<template>
  <div class="login-container" v-show="pageShow" :style="backgroundImage" id="loginBoxId" ref="login" @keyup.enter.native="handleLogin">
    <div class="login_top">
<!--      <div class="login_top_line"></div>-->
<!--      <div class="login_middle_top">-->
<!--        <img class="login_top_img" :src="logo"/>-->
<!--&lt;!&ndash;        <span class="login_top_desc">{{ title }}</span>&ndash;&gt;-->
<!--        <span class="login_top_desc">{{ title }}</span>-->
<!--        <span class="login_middle_top_line"></span>-->
<!--        <span class="login_top_title">{{ text }}</span>-->
<!--      </div>-->
<!--      <div class="login_top_line"></div>-->
    </div>
    <userLogin v-if="activeName === 'user'"></userLogin>
<!--    <div class="login_main">-->
<!--      <div class="login_main_left"></div>-->
<!--      <div class="login_main_right">-->
<!--        <div class="login-weaper animated">-->
<!--          <div class="login-border">-->
<!--            <div class="login-main">-->
<!--              <h4 class="login-title" style="letter-spacing: 2px">-->
<!--                {{ $t('login.titleText') }}-->
<!--              </h4>-->
<!--              <top-lang></top-lang>-->
<!--              <userLogin v-if="activeName === 'user'"></userLogin>-->
<!--              <codeLogin v-else-if="activeName === 'code'"></codeLogin>-->
<!--              <thirdLogin v-else-if="activeName === 'third'"></thirdLogin>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="login_bottom_about" v-html="textarea_rich">
    </div>
  </div>
</template>
<script>
import userLogin from "./userlogin";
import codeLogin from "./codelogin";
import thirdLogin from "./thirdlogin";
import {mapGetters} from "vuex";
import {dateFormat} from "@/util/date";
import {validatenull} from "@/util/validate";
import topLang from "@/page/index/top/top-lang";
import topColor from "@/page/index/top/top-color";
import {getQueryString, getTopUrl} from "@/util/util";
import {getLogo} from "@/api/system/params";
import {getSysConfig} from "@/api/system/user";
import login from "@/page/login/login";
export default {
  name: "login",
  mixins:[login],
  components: {
    userLogin,
    codeLogin,
    thirdLogin,
    topLang,
    topColor
  },
  data() {
    return {
      pageShow:false,
      time: "",
      activeName: "user",
      socialForm: {
        tenantId: "000000",
        source: "",
        code: "",
        state: "",
      },
      logo: '',
      text: '',
      title: '',
      Welcome: '',
      networkSecurity: '',
      copyright: '',
      textarea_rich: '',
      backgroundImage: ''
    };
  },
  watch: {
    $route() {
      this.handleLogin();
    }
  },
  created() {
    this.getinfomation();
    this.handleLogin();
    this.initUrl();
    this.getTime();
  },
  computed: {
    ...mapGetters(["website", "tagWel", "language", "tag"]),
  },
  mounted() {
    this.getIframe();
    // window.addEventListener('message',this.getIframe)
  },
  beforeDestroy() {
    // window.removeEventListener('message',this.getIframe)
  },
  props: [],
  methods: {
    getIframe() {
      // setTimeout(() => {
      window.addEventListener('message', function (event) {
        // console.log(event)
        let loginBoxId = document.getElementById('loginBoxId');
        loginBoxId.style.display = event.data;
      }, false)
      // }, 1000);
    },


    getinfomation() {
      let lang = this.language
      getSysConfig().then(res=>{
        this.logo = res.data.data.logo_icon
        this.title = res.data.data.title
        this.text = res.data.data.sys_edition
        this.textarea_rich = res.data.data.sys_description
        this.backgroundImage = `backgroundImage: url(${res.data.data.background})`
      })
    },
    getTime() {
      setInterval(() => {
        this.time = dateFormat(new Date());
      }, 1000);
    },
    handleLogin() {
      const topUrl = getTopUrl();
      const redirectUrl = "/oauth/redirect/";
      this.socialForm.source = getQueryString("source");
      this.socialForm.code = getQueryString("code");
      this.socialForm.state = getQueryString("state");
      if (validatenull(this.socialForm.source) && topUrl.includes(redirectUrl)) {
        let source = topUrl.split("?")[0];
        source = source.split(redirectUrl)[1];
        this.socialForm.source = source;
      }
      if (!validatenull(this.socialForm.source) && !validatenull(this.socialForm.code) && !validatenull(this.socialForm.state)) {
        const loading = this.$loading({
          lock: true,
          text: '第三方系统登录中,请稍后。。。',
          spinner: "el-icon-loading"
        });
        this.$store.dispatch("LoginBySocial", this.socialForm).then(() => {
          window.location.href = topUrl.split(redirectUrl)[0];
          this.$router.push({path: this.tagWel.value});
          loading.close();
        }).catch(() => {
          loading.close();
        });
      }
    },
    toLogin(){
      if(location.href.includes('hse.sinoma.com.cn')){
        let redirectedFrom=`${location.origin}/?#${this.$route.redirectedFrom || '/login'}`
        location.replace(`https://home.sinoma.com.cn/cas/login?client_id=818a609d01820001&service=${redirectedFrom}`)
      }else {
        this.pageShow=true
      }
      // https://home.sinoma.com.cn/cas/login?client_id=818a609d01820001&service=https://hse.sinoma.com.cn:8000/#/reportManagement/dataFilling/index?id=1734820115407413250
    },
    initUrl(){
      let wxCode =getQueryString('wxCode');
      let ticket =getQueryString('ticket');
      let code =getQueryString('code');
      if (wxCode || ticket|| code) {
        if (wxCode) {
          this.loginForm.wx_code =wxCode
          this.loginForm.grant_type ='wx_code'
        } else if (code) {
          this.loginForm.sinoma_code =code
          this.loginForm.grant_type ='sinoma_code'
        } else {
          this.loginForm.sinoma_code =ticket
          this.loginForm.grant_type ='sinoma_code'
        }
        this.login()
      } else {
        this.toLogin()
      }
    },
    login() {
      if(this.grant_type.includes(this.loginForm.grant_type)){
        this.handleRequest()
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/styles/login.scss";
</style>
