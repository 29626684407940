<template>
  <div>
    <div class="disFlex">
      <span style="padding-left: 30px"><i style="color: red">{{ $t('userinfo.title1') }}</i>{{ $t('userinfo.title2') }}</span>
      <i class="el-icon-circle-close" style="font-size: 30px;cursor: pointer" @click="close"></i>
    </div>

    <el-row style="padding-top: 20px; width: 35%;margin: auto;">
      <avue-form ref="password"
                 v-model="passwdForm"
                 :option="passwdOption"
                 @submit="handleSubmit"
                 @return-login="returnLogin()">
      </avue-form>
    </el-row>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {updataByFirstLogin, updatePassword} from "@/api/system/user";
import md5 from "js-md5";

export default {
  components: {},
  data() {
    return {
      passwdOption: {
        column: [{
          label: this.$t('userinfo.oldpasswd'),
          span: 24,
          type: 'password',
          prop: 'oldPassword',
        }, {
          label: this.$t('userinfo.newPasswd'),
          span: 24,
          maxlength: 30,
          type: 'password',
          prop: 'newPassword',
          rules: [
            {
              pattern: /^[^\s]*$/,
              message: this.$t("cip.cmn.rule.removeSpacesWarning"),
            },
          ],
        }, {
          label: this.$t('userinfo.confirmPasswd'),
          span: 24,
          type: 'password',
          prop: 'newPassword1',
        }]
      },
      passwdForm: {},
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.returnLoginBtn'),
        emit: "return-login",
        type: "button",
        icon: ""
      },]
    }
  },
  computed: {
    ...mapGetters(['userInfo','passwordStatus'])
  },
  mounted() {
    // 禁用浏览器返回键
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', this.disableBrowserBack);
  },
  destroyed() {
    // 清除popstate事件 否则会影响到其他页面
    window.removeEventListener("popstate", this.disableBrowserBack, false);
  },
  methods:{
    close() {
      // this.userInfo.first_login = 'Y';
      // this.$store.commit('SET_USER_INFO', this.userInfo)
      this.$store.dispatch("LogOut").then(() => {
        this.$router.push({path: '/login'});
      });
    },
    handleSubmit(form, done) {
      if (form.oldPassword == null || form.oldPassword == '' || form.oldPassword == undefined) {
        this.$message.warning(this.$t('userinfo.oldPasswordNotNull'))
        done()
        return false
      }
      if (form.newPassword == null || form.newPassword == '' || form.newPassword == undefined) {
        this.$message.warning(this.$t('userinfo.newPasswordNotNull'))
        done()
        return false
      }
      if (form.newPassword != form.newPassword1) {
        this.$message.warning(this.$t('userinfo.differentPassword'))
        done()
        return false
      }
      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
      if (!pwdRegex.test(form.newPassword)) {
        this.$message.warning(this.$t('userinfo.passwdMsg'))
        done()
        return false
      }
      this.$refs.password.validate(valid => {
        if (valid) {
          updatePassword(md5(form.oldPassword), md5(form.newPassword), md5(form.newPassword1)).then(res => {
            if (res.data.success) {
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.updataSuccess")
              });
              updataByFirstLogin(this.userInfo.user_id);
              this.userInfo.first_login = 'Y';
              this.$store.commit('SET_USER_INFO', this.userInfo);
              this.$router.push({path: '/cip/index'});
            } else {
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
            done();
          }, error => {
            window.console.log(error);
            done();
          })
        }
      })
    },
    returnLogin() {
      // this.$router.push({ path: '/login' });
    },
    disableBrowserBack() {
      history.pushState(null, null, document.URL);
    }
  }
}
</script>
<style scoped>
.disFlex {
  width: calc(100% - 48px);
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
</style>
