<script>
import {getTokenByShort} from '@/api/home'
import {formatTime} from "@/util";
import {getDept} from "@/api/system/dept";
import {myWtTicketPage, ticketType} from "@/api/workTicket";
import {mapGetters} from "vuex";
export default {
  data(){
    return {

    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods:{
    async getToken(){
      let {processId,url,taskCode,taskType,taskDept,createTime,processInstanceId,processDefKey,taskId,shortToken,pageType}=this.$route.query
      if(!shortToken)return
     await getTokenByShort({shortToken:shortToken}).then(res=>{
       this.$store.commit('SET_TOKEN', res.data.data.access_token);
       this.$store.commit('SET_REFRESH_TOKEN', res.data.data.refresh_token);
       this.$store.commit('SET_TENANT_ID', res.data.data.tenant_id);
       this.$store.commit('SET_USER_INFO', res.data.data);
       this.$store.commit('SET_TAGSVIEW', false); //多页签
      })
      if(pageType == 'todoList'){
        if(taskType){
          if (taskType == 1) {
            if ("overseasCode" == taskCode || "domesticCode" == taskCode) {
              let formDate = formatTime(createTime, "yyyy-MM-dd");
              location.href =window.location.origin +"/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=" + taskCode + "&statDate=" + formDate + "&fillDeptId=" + taskDept
            } else {
              let formDate = formatTime(createTime, "yyyy-MM");
              location.href= window.location.origin +"/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=" + taskCode + "&statDate=" + formDate + "&fillDeptId=" + taskDept
            }
          } else if (taskType == 2) {
            getDept(taskDept).then((res) => {
              let dateForm = res.data.data;
              let deptName = JSON.parse(dateForm.deptName).zh;
              if ("overseasCode" == taskCode || "domesticCode" == row.taskCode) {
                let formDate = formatTime(createTime, "yyyy-MM-dd");
                location.href= window.location.origin +"/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=" + taskCode + "&statDate=" + formDate + "&fillDeptId=" + taskDept + "&fillDeptName=" + deptName
              } else {
                let formDate = formatTime(createTime, "yyyy-MM");
                location.href= window.location.origin +"/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=" + taskCode + "&statDate=" + formDate + "&fillDeptId=" + taskDept + "&fillDeptName=" + deptName
              }
            });
          }
        }else {
          let param = Buffer.from(
            JSON.stringify({
              processId: processId,
              taskId:taskId,
              processInsId: processInstanceId,
              processDefKey:processDefKey,
            })
          ).toString("base64");
          location.href = window.location.origin + "#/process/external/iframe/detail?p=" + param + "&isNewWindow=true";
        }
      } else if(pageType == 'taskList'){
        if (taskType == 1) {
          if ("overseasCode" == taskCode || "domesticCode" == taskCode) {
            let formDate = formatTime(createTime, "yyyy-MM-dd");
            location.href =window.location.origin + "/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=" +
              taskCode +
              "&statDate=" +
              formDate +
              "&fillDeptId=" +
              taskDept
          } else {
            let formDate = formatTime(createTime, "yyyy-MM");
            location.href =window.location.origin + "/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=" + taskCode + "&statDate=" + formDate + "&fillDeptId=" + taskDept
          }
        } else if (taskType == 2) {
          getDept(taskDept).then((res) => {
            let dateForm = res.data.data;
            let deptName = JSON.parse(dateForm.deptName).zh;
            if ("overseasCode" == taskCode || "domesticCode" == taskCode) {
              let formDate = formatTime(createTime, "yyyy-MM-dd");
              location.href =window.location.origin + "/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=" +
                taskCode +
                "&statDate=" +
                formDate +
                "&fillDeptId=" +
                taskDept +
                "&fillDeptName=" +
                deptName
            } else {
              let formDate = formatTime(createTime, "yyyy-MM");
              location.href =window.location.origin + "/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=" +
                taskCode +
                "&statDate=" +
                formDate +
                "&fillDeptId=" +
                taskDept +
                "&fillDeptName=" +
                deptName
            }
          });
        } else if (taskType == 3) {
              location.href =window.location.origin + "/#/business/safetyenment/train/trainingEducation/index"
          // this.$router.push({
          //   path: `/business/safetyenment/train/trainingEducation/index`,
          //   query: {},
          // });
        } else if (taskType == 4) {
          location.href =window.location.origin + "/#/business/safetyenment/train/myExam/eduuserexam?taskId="+taskCode
          // this.$router.push({
          //   path: `/business/safetyenment/train/myExam/eduuserexam`,
          //   query: {taskId: row.taskCode},
          // });
        } else if (taskType == 5) {
          location.href =window.location.origin + "/#/jobSlip/jobSlipInventory/add?type=sign&taskId="+taskCode
          // this.$router.push({
          //   path: `/jobSlip/jobSlipInventory/add`,
          //   query: {
          //     type: "sign",
          //     taskId: row.taskCode,
          //   },
          // });
        } else if (taskType == 7) {
          this.openTicket(taskCode);
        } else if (taskType == 10) {
          let code = "";
          if (taskCode.includes("-")) {
            code = taskCode.split("-")[0];
          } else {
            code = taskCode
          }
          myWtTicketPage(
            1,
            5,
            {
              type: 'my',
              ticketCode: code,
              orgId: this.userInfo.dept_id,
              createDept: this.userInfo.dept_id
            }
          ).then(async (res) => {
            const data = res.data.data;
            var record = data.records[0];
            if (record&&record.statuSort == 2) {
              //线下
              this.$router.push({
                path: "/jobSlip/jobTicketList/offline",
                query: {
                  type: "flow",
                  ticketCode: record.ticketCode,
                  id: record.id,
                  businessStatus: record.businessStatus,
                  characters: record.characters,
                  processInstanceId: record.processInstanceId,
                }
              })
            } else {
              //线上
              let item = this.kictectTypeList.find(e => e.dictKey == record.ticketType)
              item.routeObj.query.ticketCode = record.ticketCode
              item.routeObj.query.id = record.id
              item.routeObj.query.type = "flow"
              item.routeObj.query.businessStatus = record.businessStatus
              item.routeObj.query.characters = record.characters
              item.routeObj.query.processInstanceId = record.processInstanceId
              this.$router.push(item.routeObj);
            }
          });
        } else if (taskType == 11) {
          location.href =window.location.origin + `/#/hiddenTrouble/riskitemView/rectify/edit?id=${taskCode}&type=edit`
          // 隐患待办
          // this.$router.push({
          //   path: `/hiddenTrouble/riskitemView/rectify/edit?id=${row.taskCode}&type=edit`,
          // });
        }
      }else if(pageType == 'video'){
        location.href =window.location.origin + '/#/visualization/wxViode/index'
      }


    },
    openTicket(data) {
      let code = data.split("-")[0];
      myWtTicketPage(1, 5, {
        createUser: this.userInfo.user_id,
        ticketCode: code,
      }).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          let ticketDetail = data.records[0];
          let item = this.kictectTypeList.find(
            (e) => e.dictKey === ticketDetail.ticketType
          );
          item.routeObj.query.ticketCode = ticketDetail.ticketCode;
          item.routeObj.query.id = ticketDetail.id;
          item.routeObj.query.type = "sign";
          item.routeObj.query.businessStatus = ticketDetail.businessStatus;
          this.$router.push(item.routeObj);
        }
      });
    },
  },
  created() {
    this.getToken()
  }
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>
