<template>
  <div class="loginBox" >
    <div class="login-main">
      <img class="loginImg" src="@/assets/images/loginImg.png" />
      <div class="loginTitle">中材国际智能安全平台</div>
      <div v-show="showCode">
        <el-form
          ref="form"
          :model="loginForm"
          label-width="0"
          :rules="loginRules"
          style="margin: 40px 0"
        >
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              placeholder="请输入您的账号"
              style="line-height: 52px"
            >
              <i slot="prefix" class="icon-yonghu"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              show-password
              v-model="loginForm.password"
              placeholder="请输入您的密码"
              @keyup.enter.native="login"
              style="line-height: 52px"
            >
              <i slot="prefix" class="icon-mima"></i>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button
          size="medium"
          class="loginBtn"
          type="primary"
          @click.native.prevent="handleLoginPrev"
          >登 录</el-button
        >
      </div>
      <div v-show="!showCode">
        <div class="qr-content">
          <div class="qyCode-box">
            <span class="qwImage" id="wx_reg"></span>
          </div>
        </div>
        <div class="qr-title">
          <template>
            <span style="font-size: 14px; color: #888888"
              >打开企业微信 扫一扫</span
            >
          </template>
        </div>
      </div>
    </div>
    <img
      v-if="showCode"
      class="top-icon"
      src="@/assets/images/erweima.png"
      alt=""
      @click="changeCode"
    />
    <img
      v-if="!showCode"
      class="top-icon"
      src="@/assets/images/pc.png"
      @click="changeCode"
      alt=""
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import website from "@/config/website";
import {getQueryString, getTopUrl} from "@/util/util"
import login from "@/page/login/login";
export default {
  mixins:[login],
  data() {
    return {
      pageShow:false,
      tenantMode: this.website.tenantMode,
      loginRules: {
        username: [
          { required: true, message: "请输入您的账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入您的密码", trigger: "blur" },
          {
            min: 1,
            message: this.$t("cip.plat.sys.user.msg.passwordLength"),
            trigger: "blur",
          },
        ],
      },
      showCode: true,
    };
  },
  computed: {
    ...mapGetters(["tagWel", "userInfo",'token']),
  },
  created() {
  },
  methods: {
    changeCode() {
      this.showCode = !this.showCode;
      if (!this.showCode) {
        let redirectUri = process.env.VUE_APP_BASE_ENTERPRISEWECHATURL+'/#/wxDefaultBrower';
          // process.env.NODE_ENV === "production"
          //   ? "http://hse.sinoma.com.cn/#/wxDefaultBrower"
          //   : "http://hse.snowsoft.cn/#/wxDefaultBrower";
        var wwLogin = new WwLogin({
          id: "wx_reg",
          appid: process.env.VUE_APP_BASE_CORPID, // 填你的企业微信企业id
          agentid: process.env.VUE_APP_BASE_AGENTID, // 填你的自建应用id
          redirect_uri: encodeURIComponent(redirectUri), // 填你的可信域名里的跳转链接，一定要有http或者https
          state: "null",
        });
      }
    },
    handleLoginPrev(){
      this.loginForm.grant_type ='password'
      this.loginForm.sinoma_code =''
      this.login()
    },
    login() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.handleRequest()
          }
        });
    },


  },
};
</script>
<style lang="scss" scoped>
.loginBox {
  width: 560px;
  height: 560px;
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 20vh;
  right: 10vw;
  display: flex;
  align-items: center;
}

.login-main {
  margin: 0 auto;
  width: 430px;
}

.loginImg {
  width: 230px;
  height: 40px;
  display: block;
  margin: auto;
}

.loginTitle {
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #000;
  margin: 13px 0;
  font-size: 30px;
}

::v-deep .el-input--small .el-input__inner {
  height: 52px;
  line-height: 52px;
}

.loginBtn {
  width: 100%;
  height: 52px;
  font-size: 16px;
}
.top-icon {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
}
.qr-content {
  width: 180px;
  height: 180px;
  margin: 20px auto;
  position: relative;

  .code-modal {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    border-radius: 0px 0px 0px 0px;
    background: rgba(97, 97, 97, 0.5);
    z-index: 10;
  }

  .qyCode-box {
    position: relative;
    height: 180px;
    overflow: hidden;
    top: 0;

    .qwImage {
      position: absolute;
      top: -60px;
      left: -36px;
      width: 180px;
      height: 180px;
      transform: scale(0.65, 0.65);
    }
  }
}

.qr-title {
  text-align: center;
}
</style>
