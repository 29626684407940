<template>
  <div style="height:100vh;overflow: auto;">
    <div class="topImg"></div>
    <div class="disflex">
      <div class="topLeft">
        <img src="@/assets/images/topLeft.jpg">
      </div>
      <div class="topRight">
        <div class="appLogo">
          <img src="@/assets/images/appLogo.png">
        </div>
        <div class="appTitle">水泥智能工厂</div>
        <div class="appSuit">
          <div class="suitBox">
            <img src="@/assets/images/Android.png">
            <div style="font-size: 20px">适用于 Android 设备</div>
            <img class="downloadImg" src="@/assets/download/Android.jpg">
            <div style="margin: 10px 0">Android APP下载</div>
            <a href="https://www.pgyer.com/BKHNu1">https://www.pgyer.com/BKHNu1</a>
          </div>
          <div class="suitBox">
            <img src="@/assets/images/iphone.png">
            <div style="font-size: 20px">适用于 Iphone 设备</div>
            <img class="downloadImg" src="@/assets/download/IOS.jpg">
            <div style="margin: 10px 0">Iphone APP下载</div>
            <a href="https://www.wkzx.store/vip/4XIs">https://www.wkzx.store/vip/4XIs</a>
          </div>
        </div>
      </div>
    </div>
    <div class="appInformation">
      <div class="informationTitle">应用介绍</div>
      <div class="informationContent">
        应用于北海铁山港水泥开发有限公司移动办公使用，实现随时随地的查看数据，录入数据，监测厂内设备情况
      </div>
    </div>
    <div class="appInformation">
      <div class="informationTitle">应用信息</div>
      <div class="informationContent">
        <div class="version-info">
          <div>大小</div>
          <div>36.3 MB</div>
        </div>
        <div class="version-info">
          <div>更新时间</div>
          <div>2023-07-30</div>
        </div>
        <div class="version-info">
          <div>版本</div>
          <div>2.7.3 (build 6)</div>
        </div>
      </div>
    </div>
    <div class="appInformation">
      <div class="informationTitle">历史版本</div>
      <template>
        <el-table
          :data="tableData"
          size="medium"
          :stripe="true"
          style="width: 100%">
          <el-table-column
            prop="version"
            label="版本"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="content"
            label="更新内容"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="date"
            label="时间"
            align="center"
          >
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="appInformation">
      <div class="informationTitle">产品展示</div>
      <template>
        <el-carousel :interval="4000" type="card" height="900px">
          <el-carousel-item v-for="(item,index) in imageList" :key="index">
            <img class="appShowImg" :src="item.src">
          </el-carousel-item>
        </el-carousel>
      </template>
    </div>
    <div class="bottomBox">
      <div class="bottomContent">
        <p style="color: #FFFFFF">SnowSoft</p>
        <p @click="aboutShow = true"><a>关于我们</a></p>
        <p @click="linkShow = true"><a>联系我们</a></p>
      </div>
      <div class="bottomContent">
        <p style="color: #FFFFFF">商务合作</p>
        <p><a>业务合作</a></p>
        <p><a>赞助投资</a></p>
      </div>
      <div class="bottomContent">
        <p style="color: #FFFFFF">服务支持</p>
        <p><a>用户协议</a></p>
        <p><a>隐私协议</a></p>
      </div>
    </div>
    <el-dialog
      title="关于我们"
      :visible.sync="aboutShow"
      width="30%">
      <span>北海铁山港水泥开发有限公司办公室地址位于中国西南地区唯一的沿海开放城市北海，北海 公馆镇陈村（邮编：536110），公司成立以来发展迅速，业务不断发展壮大，我公司主要经营水泥，生产，销售，，我公司与多家北海公司建立了长期稳定的合作关系，重信用、守合同、保证产品质量，赢得了广大客户的信任，公司全力跟随客户需求，不断进行产品创新和服务改进。</span>
    </el-dialog>
    <el-dialog
      title="联系我们"
      :visible.sync="linkShow"
      width="30%">
      <p>地址：北海 公馆镇陈村</p>
      <p>电话：(0779)</p>
      <p>联系人：未提供</p>
      <p>邮编：536110</p>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          version: '2.7.2 (build 5)',
          content: '',
          date: '2023-07-24'
        },
        {
          version: '2.7.1 (build 4)',
          content: '',
          date: '2023-07-19'
        },
        {
          version: '1.3.5 (build 3)',
          content: '完善平台功能',
          date: '2023-07-17'
        },
        {
          version: '1.2.6 (build 2)',
          content: '增加了在线更新等功能',
          date: '2023-06-09'
        },
        {
          version: '1.2.5 (build 1)',
          content: '初始版本，提供平台所需功能',
          date: '2023-06-06'
        }
      ],
      imageList:[
        {src:require('@/assets/appImage/one.jpg')},
        {src:require('@/assets/appImage/two.jpg')},
        {src:require('@/assets/appImage/three.jpg')},
        {src:require('@/assets/appImage/four.jpg')},
        {src:require('@/assets/appImage/five.jpg')},
        {src:require('@/assets/appImage/six.jpg')},
        {src:require('@/assets/appImage/seven.jpg')},
        {src:require('@/assets/appImage/eight.jpg')},
        {src:require('@/assets/appImage/nine.jpg')},
        {src:require('@/assets/appImage/ten.jpg')},
        {src:require('@/assets/appImage/eleven.jpg')},
        {src:require('@/assets/appImage/twelve.jpg')},
      ],
      aboutShow:false,
      linkShow:false
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.topImg {
  width: 100%;
  height: 120px;
  background-image: url("~@/assets/images/appShowTop.png");
  background-size: contain;
  background-repeat-x: repeat;
  margin-bottom: 20px;
}

.disflex {
  width: 100%;
  display: flex;
}

.topLeft {
  width: 60%;

  img {
    width: 100%;
  }
}

.topRight {
  width: 40%;
}

.appLogo {
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;

  img {
    width: 100%;
    height: 100%;
  }
}

.appTitle {
  color: #000000;
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 33px;
  margin-top: 5px;
}

.appSuit {
  width: 100%;
  display: flex;
  margin-top: 48px;

  .suitBox {
    width: 50%;
    text-align: center;

    img {
      display: block;
      margin: auto;
      margin-bottom: 24px;
      width: 70px;
      height: 70px;
    }

    .downloadImg {
      width: 100px;
      height: 100px;
      margin-top: 24px;
    }
  }
}


.appInformation {
  width: 50%;
  margin: 40px auto 0;
  border-top: 1px solid #e7ebed;

  .informationTitle {
    width: 100%;
    color: #0A0D26;
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    margin: 40px auto 20px;
  }

  .informationContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .version-info {
      width: 40%;
      margin-top: 5px;
      padding: 5px 0;
      border-bottom: 1px solid #e7ebed;
      display: flex;
      justify-content: space-between;
    }
  }

  .appShowImg {
    width: 100%;
    height: 900px;
  }
}

.bottomBox {
  width: 50%;
  padding: 30px 25%;
  background-color: #1f2730;
  display: flex;
  justify-content: space-between;
  .bottomContent {
    a {
      color: #aaaaaa;
      font-size: 15px;
      cursor: pointer;
    }

  }
}
</style>
