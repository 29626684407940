import {userStyleDetail} from "@/api/user";

export default {
  data(){
    return {
      grant_type:['wx_code','sinoma_code'],
      loginForm: {
        //租户ID
        tenantId: "000000",
        //部门ID
        deptId: "",
        //角色ID
        roleId: "",
        //用户名
        username: "",
        //密码
        password: "",
        grant_type: "password",
        //账号类型
        type: "account",
        //验证码的值
        code: "",
        //验证码的索引
        key: "",
        //预加载白色背景
        image:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      },
    }
  },
  methods:{
   async getUser(loginRes){
     let res=await userStyleDetail(loginRes.data.user_id).then((res) => res.data.data)
      this.$store.commit('SET_LAYOUTTYPE', res.navigateMode);
      this.$store.commit('SET_SLIDECLASS', res.colorBlock);
     this.$store.commit("SET_THEME_NAME", res.colorBlock);
      this.$store.commit('SET_THEMECOLOR', res.themeColors);
      this.$store.commit('SET_THEMECLASS', res.themeClass);
      this.$store.commit('SET_TAGSVIEW', res.multiTab == 'true' ? true : false); //多页签
      this.$store.commit('SET_SHOWSTRIPE', res.tableStyle == 'true' ? true : false); //斑马纹
      this.$store.commit('SET_SHOWSEARCH', res.searchBox == 'true' ? true : false); //搜索框
    },

    async  handleRequest() {
      this.$loading({
        lock: true,
        text: '系统正在登录初始化，请稍等',
        spinner: 'el-icon-loading',
      })
      let loginRes = await this.$store.dispatch("LoginByUsername", this.loginForm).then((res) => res).catch((err) => {
        this.pageShow=true
        return err
      })
      if (loginRes.message) {
        this.$loading().close()
        return
      }
      if (this.website.switchMode) {
        const deptId = this.userInfo.dept_id;
        const roleId = this.userInfo.role_id;
        if (deptId.includes(",") || roleId.includes(",")) {
          this.loginForm.deptId = deptId;
          this.loginForm.roleId = roleId;
          this.userBox = true;
          this.$store.dispatch("LogOut").then(() => {});
          return false;
        }
      }
      await this.getUser(loginRes)
      await this.$store.dispatch('GetMenu').then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true)
        }else {
          this.$loading().close()
        }
      }).catch(err=>{
        this.$message.error(err)
        this.$loading().close()
      })
      this.$router.push({ path: this.$route.redirectedFrom ||this.tagWel.value })
      this.$loading().close()
    },
  },
}
