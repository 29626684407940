var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.pageShow,
          expression: "pageShow",
        },
      ],
      ref: "login",
      staticClass: "login-container",
      style: _vm.backgroundImage,
      attrs: { id: "loginBoxId" },
      nativeOn: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleLogin.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "login_top" }),
      _vm.activeName === "user" ? _c("userLogin") : _vm._e(),
      _c("div", {
        staticClass: "login_bottom_about",
        domProps: { innerHTML: _vm._s(_vm.textarea_rich) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }